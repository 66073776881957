.login-container {
    text-align: center;
    margin-top: 20px;
    padding-bottom: 50px;
    
  }
  
  .searchbar-wrapper {
    position: relative;
    width: 60%;
    margin: 0 auto;
  }
  
  .searchbar-placeholder {
    width: 100%;
    height: 200px; /* Adjust based on the size of your actual search bar */
    border-radius: 15px;
    padding-left: 20px;
    font-size: 18px;
    color: #fff;
    background-color: #11aba0;
    box-shadow: 0px 0px 4px 0.3px rgba(166, 166, 166, 1);
    border: none;
  }
  
  .searchbar-placeholder:disabled {
    color: #fff;
  }
  
  .lock-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 0px;
    padding-bottom: 1%;
    
  }
  
  .lock-icon {
    width: 80px;
    height: 80px;
    
  }
  @media (min-width: 800px) {
    .lock-icon {
        width: 120px; /* Larger size for larger screens */
        height: 120px;
    }
}
  

  .gsi-material-button {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    -webkit-appearance: none;
    background-color: WHITE;
    background-image: none;
    border: 1px solid #747775;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: #1f1f1f;
    cursor: pointer;
    font-family: 'Roboto', arial, sans-serif;
    font-size: 14px;
    height: 40px;
    letter-spacing: 0.25px;
    outline: none;
    overflow: hidden;
    padding: 0 12px;
    position: relative;
    text-align: center;
    -webkit-transition: background-color .218s, border-color .218s, box-shadow .218s;
    transition: background-color .218s, border-color .218s, box-shadow .218s;
    vertical-align: middle;
    white-space: nowrap;
    width: auto;
    max-width: 400px;
    min-width: min-content;
  }
  
  .gsi-material-button .gsi-material-button-icon {
    height: 20px;
    margin-right: 12px;
    min-width: 20px;
    width: 20px;
  }
  
  .gsi-material-button .gsi-material-button-content-wrapper {
    -webkit-align-items: center;
    align-items: center;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
    height: 100%;
    justify-content: space-between;
    position: relative;
    width: 100%;
  }
  
  .gsi-material-button .gsi-material-button-contents {
    -webkit-flex-grow: 1;
    flex-grow: 1;
    font-family: 'Roboto', arial, sans-serif;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: top;
  }
  
  .gsi-material-button .gsi-material-button-state {
    -webkit-transition: opacity .218s;
    transition: opacity .218s;
    bottom: 0;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  
  .gsi-material-button:disabled {
    cursor: default;
    background-color: #ffffff61;
    border-color: #1f1f1f1f;
  }
  
  .gsi-material-button:disabled .gsi-material-button-contents {
    opacity: 38%;
  }
  
  .gsi-material-button:disabled .gsi-material-button-icon {
    opacity: 38%;
  }
  
  .gsi-material-button:not(:disabled):active .gsi-material-button-state, 
  .gsi-material-button:not(:disabled):focus .gsi-material-button-state {
    background-color: #303030;
    opacity: 12%;
  }
  
  .gsi-material-button:not(:disabled):hover {
    -webkit-box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .30), 0 1px 3px 1px rgba(60, 64, 67, .15);
    box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .30), 0 1px 3px 1px rgba(60, 64, 67, .15);
  }
  
  .gsi-material-button:not(:disabled):hover .gsi-material-button-state {
    background-color: #303030;
    opacity: 8%;
  }
  .signin-messages {
    background-color: hsl(0, 0%, 100%);
    
    color: #000000;
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 13.5px; /* Default font size for smaller screens */
    margin-top: 5px;
    border-radius: 10px;
    text-align: center;
    z-index: 2;
     /* Ensures it is above the lock-overlay */
}

.legal-message {
  font-size: 9px;
  color: #666;
  margin-top: 10px;
  font-weight: normal;
  line-height: 1.5;
}

/* Media query for screens wider than 800px */
@media (min-width: 800px) {
    .signin-messages {
        font-size: 18px; /* Larger font size for larger screens */
    }
    .legal-message {
      font-size: 13px;
  }
}

.signin-messages p {
    margin-bottom: 10px;
    line-height: 1.4;
}

.signin-messages p:last-child {
    margin-bottom: 0;
}

.unlock-container {
  background-color: #000;
  color: #fff;
  font-family: Arial, sans-serif;
  padding: 20px;
  border-radius: 10px;
}



.legal {
  margin-top: 20px;
  font-size: 12px;
  color: #ccc; /* Lighter text for legal notice */
}

.subscribe-button {
  background-color: #FF4500; /* Orangish red button */
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  display: block; /* Full-width button */
  text-align: center;
}
.trial-info {
  font-size: 16px;
  margin-bottom: 20px;
  color: #666;
}

.features-list {
  list-style-type: none; /* Removes default list styling */
  padding: 0; /* Removes default padding */
  width: 100%; /* Ensures the list takes full width of its container */
  display: flex; /* Enables flexbox properties */
  flex-direction: column; /* Aligns children elements vertically */
  align-items: left; /* Centers the list items horizontally within the container */
  text-align: left; /* Centers text within the list items */
}

.features-list li {
  display: flex; /* Aligns the icon and text horizontally */
  align-items: left; /* Vertically centers icon and text */
  margin-bottom: 10px; /* Adds space between items */
  justify-content: center; /* Centers the content within the list item */
  width: 100%; /* Each item takes full width */
  padding: 5px; 
}

.icon {
  margin-right: 15px; /* Space between icon and text */
}

.feature-title {
  font-size: 18px; /* Larger, bold text */
  font-weight: bold; /* Makes text bold */
  display: block;
  color: #64c43e;
  text-align: left;
   /* Makes the text occupy the full width */
}

.feature-detail {
  font-size: 14px; /* Smaller text */
  color: #6c6c6c; /* Lighter text color for the detail */
  text-align: left; /* Aligns the text to the left */
}
